<script>
import { Carousel, Slide } from "vue-carousel";
import { ArrowUpIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";
import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      wasiat: {},
      witness: null,
      amendment_status: null,
      amendment_id: null,
      printWasiat_id: null,
      amendment_array: null,
      amendment_index_status: null,
      btnPrint: false,
      recorded_amendment_date:null
    };
  },
  methods: {
    async getWasiat() {
      ApiService.get(`wasiat/${this.wasiatId}?load_with=agent,organization,branch,user,userDetail,heir`).then((response) => {
        this.wasiat = response.data.wasiat;
        if (this.wasiat.status == 2) {
          this.getamendment();
        }
        var wasiat = response.data.wasiat;
        console.log("wasiat", wasiat);
        if (wasiat) {
          if (wasiat.user_detail.gender == 1) {
            this.wasiat.user_detail.gender = "Lelaki";
          }
          if (wasiat.user_detail.gender == 2) {
            this.wasiat.user_detail.gender = "Perempuan";
          }
          if (wasiat.user_detail.nationality == 1) {
            this.wasiat.user_detail.nationality = "Warganegara";
          }
          if (wasiat.user_detail.nationality == 2) {
            this.wasiat.user_detail.nationality = "Bukan Warganegara";
          }
        }
      });
    },
    async getwitness() {
      ApiService.get(`/wasiat/${this.wasiatId}/witness`)
        .then((response) => {
          this.witness = response.data.witnesses.data ?? [];
        })
        .catch((error) => {});
    },
    getamendment() {
      ApiService.get(`/wasiat/${this.wasiatId}/amendment`)
        .then((response) => {
          var data_array = response.data.amendments.data;
          var array = [];
          var filter = data_array.filter((item) => item.status == 5);
          if (Array.isArray(filter) && filter.length > 0) {
            this.printWasiat_id = filter[0].id;
            this.btnPrint = true;
            console.log("printWasiat_id", filter[0]);
          }
          if (Array.isArray(data_array) && data_array.length > 0) {
            this.amendment_status = data_array[0].status;
            this.recorded_amendment_date = data_array[0].recorded_amendment_date;
            this.amendment_index_status = data_array[0].status;
            array.push(data_array[0]);
            this.amendment_array = array ?? [];
          }
        })
        .catch((error) => {});
    },
    wasiatCertificate() {
      if (this.printWasiat_id) {
        window.open(process.env.VUE_APP_API_URL + `/amendment/${this.printWasiat_id}/certificate/comprehensive`, "_blank");
      } else {
        // console.log(this.wasiat);
        // return;
        window.open(process.env.VUE_APP_API_URL + `/wasiat/${this.wasiatId}/certificate/final`, "_blank");
      }
    },
    applyAmendment() {
      if (this.amendment_index_status == null || this.amendment_index_status != 0) {
        //if pewasait is first time apply or status last pindaan is =1
        this.requestAmendment();
      } else {
        // mean pewasait do not need to register new, because die x submit lagi ke awaris
        this.$router.push({
          name: "pindaan.part_a",
          params: {
            wasiat_id: this.wasiatId,
          },
        });
      }
    },
    async requestAmendment() {
      let obj = {
        amendmentable_type: "Wasiat",
        amendmentable_id: this.wasiatId,
        notes: null,
        status: 0,
        is_staff_created: 1,
      };

      ApiService.post(`/amendment`, obj)
        .then((response) => {
          var data_obj = response.data.amendment;
          console.log(data_obj);
          console.log(this.amendment_id);
          this.amendment_id = data_obj.id;
          this.$router.push({
            name: "pindaan.part_a",
            params: {
              wasiat_id: this.wasiatId,
            },
          });
        })
        .catch((error) => {
          console.log("error");
        });
    },
    VerifiedWasiat(item, index) {
      this.$router.push({
        name: "pindaan.draft-wasiat",
        params: { wasiat_id: item.amendmentable_id },
      });
    },
    VerifiedWasiatStatus1(item, index) {
      this.$router.push({
        name: "pindaan.part_a",
        params: { wasiat_id: item.amendmentable_id },
      });
    },
    async printWasiat() {
      window.open(process.env.VUE_APP_API_URL + `/wasiat/${this.printWaisiat_id}/certificate`, "_blank");
    },
    back() {
      this.$router.push({
        name: "wasiat.list",
        query: { wasiat_id: this.wasiatId },
      });
    },
  },
  mounted() {
    if (this.wasiatId) {
      this.getWasiat();
      this.getwitness();
    }
  },
  computed: {
    ...mapGetters(["userAccess", "userRole", "currentUser"]),
    wasiatId() {
      return this.$route.params.wasiat_id;
    },
  },
  filters: {
    getConvertDate(tempDate) {
      if (!tempDate) return "-";

      var date = new Date(tempDate);
      var dateConvert = (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "/" + (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "/" + date.getFullYear();
      return dateConvert;
    },
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white">{{ $t("basic-will-info") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <h5 class="mb-4 title text-left">
                    {{ $t("wasiat-tracking1") }}
                  </h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="step ">
                        <div >
                        
                            <div 
                             :class="wasiat.status  == 0 ? 'circle-active' : 'circle'"
                              >1</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("basic-will-application") }}
                                -{{ $t("date") }}  {{wasiat ? wasiat.recorded_wasiat_date : "" | getConvertDate }}
                            </div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <div class="step">
                        <div>
                            <div :class="wasiat.status  == 1 ? 'circle-active' : 'circle'">2</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("awaiting-awaris-confirmation") }}</div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 &&amendment_status == null ? 'circle-active' : 'circle'">3</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("basic-will-confirmed") }}
                              -{{ $t("date") }}  {{wasiat ? wasiat.approved_at : "" | getConvertDate }}
                            </div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 && amendment_status == 0? 'circle-active' : 'circle'">4</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("comp-will-application") }}</div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 && amendment_status == 1? 'circle-active' : 'circle'">5</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("amendment.confirmation") }}
                              -{{ $t("date") }}  {{this.recorded_amendment_date ? this.recorded_amendment_date  : "" | getConvertDate }}                     
                            </div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 && amendment_status == 2? 'circle-active' : 'circle'">6</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("ver-comp-will-draft") }}</div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <!-- <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 && amendment_status == 3? 'circle-active' : 'circle'">6</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">Pembatalan Pindaan</div>
                            <br>
                            <br>
                        </div>
                      </div> -->
                      <!-- <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 && amendment_status == 3? 'circle-active' : 'circle'">7</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">Pewasiat menandatangani Wasiat Komprehensif</div>
                            <br>
                            <br>
                        </div>
                      </div> -->
                      <div class="step">
                        <div>
                            <div :class="wasiat.status  == 2 && amendment_status == 4? 'circle-active' : 'circle'">7</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">{{ $t("comp-will-verification") }}</div>
                            <br>
                            <br>
                        </div>
                      </div>
                      <!-- <div class="step">
                        <div>
                            <div class="circle">9</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <div class="title">Selesai</div>
                            <br>
                            <br>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <h5 class="m-0 title text-left">
                    {{ $t("wasiatasas.will-desc1") }}
                  </h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="table-responsive bg-light shadow rounded mt-4"
                      >
                        <table class="table mb-0 table-center">
                          <tbody>
                            <tr>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("amendment.no-wasiat") }}
                              </th>
                              <td style="width: 30%">
                                {{ wasiat ? wasiat.wasiat_no : "-" }}
                              </td>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("wasiatasas.type") }}
                              </th>
                              <td>
                                {{
                                  wasiat.wasiat_type == 1
                                    ? "Wasiat Islam"
                                    : "Wasiat Konvensional"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("date-of-will") }}</th>
                              <td>
                                {{ wasiat.created_at | getConvertDate }}
                              </td>
                              
                              <th class="bg-secondary">{{ $t("date-will-accepted") }}</th>
                              <td>
                                {{
                                 wasiat ? wasiat.wasiat_received_date : "-" 
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("wasiatasas.price") }}</th>
                              <td>RM{{ wasiat.price }}</td>
                              <th class="bg-secondary">Status</th>
                              <td>
                                {{
                                  wasiat.status == 0
                                    ? "Sedang Membuat Wasiat"
                                    : wasiat.status == 1
                                    ? "Tunggu Pengesahan"
                                    : (wasiat.status == 2
                                        ? "Wasiat Lulus"
                                        : "Wasiat Batal")
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("organization") }}</th>
                              <td>
                                {{
                                  wasiat.organization
                                    ? wasiat.organization.name
                                    : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("org.nav2") }}</th>
                              <td>
                                {{ wasiat.branch ? wasiat.branch.branch_name : "-" }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("agent-name") }}</th>
                              <td>
                                {{ wasiat.agent ? wasiat.agent.name : "-" }}
                              </td>
                              <th class="bg-secondary">{{ $t("agent-phone-no") }}</th>
                              <td>
                                {{
                                  wasiat.agent
                                    ? wasiat.agent.mobile_number
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 text-md-left">
                  <h5 class="m-0 title text-left">
                    {{ $t("wasiatasas.testator-desc") }}
                  </h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="table-responsive bg-light shadow rounded mt-4"
                      >
                        <table class="table mb-0 table-center">
                          <tbody>
                            <tr>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("designation") }}
                              </th>
                              <td style="width: 30%">
                                {{
                                  wasiat.user_detail ? wasiat.user_detail.designation : "-"
                                }}
                              </td>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("fullname") }}
                              </th>
                              <td>
                                {{ wasiat.user_detail ? wasiat.user_detail.name : "-" }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("new-ic") }}</th>
                              <td>
                                {{ wasiat.user_detail ? wasiat.user_detail.ic_number : "-" }}
                              </td>
                              <th class="bg-secondary">
                                {{ $t("old-ic") }}
                              </th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.other_ic_number
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("gender") }}</th>
                              <td>
                                {{ wasiat.user_detail ? wasiat.user_detail.gender : "-" }}
                              </td>
                              <th class="bg-secondary">{{ $t("citizen") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail ? wasiat.user_detail.nationality : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("mobile-no") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail ? wasiat.user_detail.mobile_number : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("home-telno") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.home_phone_number
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("email") }}</th>
                              <td colspan="3">
                                {{ wasiat.user_detail ? wasiat.user_detail.email : "-" }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">
                                {{ $t("mailing-address") }}
                              </th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.correspondence_address
                                    : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("postcode") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.correspondence_postcode
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("city") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.correspondence_city
                                    : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("state") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.correspondence_state
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("permanent-address") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.permanent_address
                                    : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("postcode") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.permanent_postcode
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("city") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.permanent_city
                                    : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("state") }}</th>
                              <td>
                                {{
                                  wasiat.user_detail
                                    ? wasiat.user_detail.permanent_state
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 text-md-left">
                  <h5 class="m-0 title text-left">
                    {{ $t("necessary-info-to-contact") }}
                  </h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="table-responsive bg-light shadow rounded mt-4"
                      >
                        <table class="table mb-0 table-center">
                          <tbody>
                            <tr>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("fullname") }}
                              </th>
                              <td style="width: 30%">
                                {{ wasiat.heir ? wasiat.heir.name : "-" }}
                              </td>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("relationship-with-heirs") }}
                              </th>
                              <td>
                                {{
                                  wasiat.heir ? wasiat.heir.relationship : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("mobile-no") }}</th>
                              <td>
                                {{
                                  wasiat.heir ? wasiat.heir.mobile_number : "-"
                                }}
                              </td>
                              <th class="bg-secondary">{{ $t("home-telno") }}</th>
                              <td>
                                {{
                                  wasiat.heir
                                    ? wasiat.heir.home_phone_number
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">
                                {{ $t("mailing-address") }}
                              </th>
                              <td>
                                {{ wasiat.heir ? wasiat.heir.address : "-" }}
                              </td>
                              <th class="bg-secondary">{{ $t("postcode") }}</th>
                              <td>
                                {{ wasiat.heir ? wasiat.heir.postcode : "-" }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("city") }}</th>
                              <td>
                                {{ wasiat.heir ? wasiat.heir.city : "-" }}
                              </td>
                              <th class="bg-secondary">{{ $t("state") }}</th>
                              <td>
                                {{ wasiat.heir ? wasiat.heir.state : "-" }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 text-md-left">
                  <h5 class="title text-left">{{ $t("list-of-witnesses1") }}</h5>
                  <div
                    class="row"
                    v-for="(witness, index) in witness"
                    :key="index"
                  >
                    <div class="col-md-12">
                      <h6 class="title text-left mt-4">
                        {{ $t("witness1") }} {{ index + 1 }}
                      </h6>
                      <div class="table-responsive bg-light shadow rounded">
                        <table class="table mb-0 table-center">
                          <tbody>
                            <tr>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("fullname") }}
                              </th>
                              <td style="width: 30%">
                                {{ witness ? witness.name : "-" }}
                              </td>
                              <th class="bg-secondary" style="width: 20%">
                                {{ $t("new-ic") }}
                              </th>
                              <td>
                                {{ witness ? witness.ic_number : "-" }}
                              </td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">
                                {{ $t("mailing-address") }}
                              </th>
                              <td>{{ witness ? witness.address : "-" }}</td>
                              <th class="bg-secondary">{{ $t("postcode") }}</th>
                              <td>{{ witness ? witness.postcode : "-" }}</td>
                            </tr>
                            <tr>
                              <th class="bg-secondary">{{ $t("city") }}</th>
                              <td>{{ witness ? witness.city : "-" }}</td>
                              <th class="bg-secondary">{{ $t("state") }}</th>
                              <td>{{ witness ? witness.state : "-" }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" v-if="userRole =='Staff'">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 text-md-left">
                  <h5 class="title text-left">{{ $t("info-about-amendments") }}</h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="pindaan">
                        <div v-if="wasiat.status == 2">
                          <div class="d-flex justify-content-end m-3">
                          <b-button
                            v-if="[null,5].includes(amendment_index_status)  && userRole =='Staff'"
                            @click="applyAmendment"
                            class="btn-sm btn-primary m-1"
                            >{{ $t("amendment.apply-new") }}</b-button
                          >
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive bg-light shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th class="bg-secondary">
                                {{ $t("index") }}
                              </th>
                              <th class="bg-secondary">
                                {{ $t("amendment.officer") }}
                              </th>
                              <th class="bg-secondary">
                                {{ $t("agent-phone-no") }}
                              </th>
                              <th class="bg-secondary">
                                {{ $t("amendment.date-of-amendment") }}
                              </th>
                              <th class="bg-secondary">
                                {{ $t("amendment.of") }}
                              </th>
                              <th class="bg-secondary">
                                Status
                              </th>
                              <th class="bg-secondary">
                                {{ $t("action") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="amendment_array == null">
                            <tr>
                              <td colspan="7" class="text-center">
                                <h5 class="mt-5">{{ $t("no-amendments") }}</h5>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="amendment_array">
                            <tr v-for="(item,index) in amendment_array" :key="index">
                              <td>
                                {{ index+1 }}
                              </td>
                              <td>
                                {{ item ? item.incharged_by_id : "-" }}
                              </td>
                              <td>
                                999
                              </td>
                              <td>
                                {{ item ? item.created_at : '-' |getConvertDate }}
                              </td>
                              <td>
                                {{ item ? item.count : '-' }}
                              </td>
                              <td>
                                {{ item.status
                                ? item.status == 0 ? this.$t("in-process")
                                : item.status == 1 ? this.$t("application-sent")
                                : item.status == 2 ? "Terima Draf Pindaan"
                                : item.status == 4 ? "Pengesahan Saksi" 
                                : item.status == 5 ? "Pindaan Berjaya"
                                : "-"
                                : "-" }}
                              </td>
                              <td class="text-left" v-if="item.status == 0">
                                <router-link 
                                  tag="button"
                                  class="btn btn-secondary btn-sm"
                                  :to="{ name: 'pindaan.part_a',
                                  params: { wasiat_id: item.amendmentable_id} }" > 
                                  {{ $t("update") }}
                                </router-link>
                              </td>
                              <td class="text-left" v-if="item.status == 1">
                                <b-button class="btn-sm btn-secondary"
                                  @click="VerifiedWasiatStatus1(item, index)">
                                  {{ $t("verification") }}
                                </b-button>
                              </td>
                              <td class="text-left" v-if="item.status == 2">
                                <b-button class="btn-sm btn-secondary"
                                  @click="VerifiedWasiat(item, index)">
                                  {{ $t("verification-draft-will") }}
                                </b-button>
                              </td>
                             
                              <td class="text-left" v-if="item.status == 4">
                                {{ $t("draft-received") }}
                              </td>
                              <td class="text-left" v-if="item.status == 5">
                                {{ $t("print-cert") }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 text-md-left">
                  <h5 class="m-0 title text-left">
                    {{ $t("pdpa") }}
                  </h5>
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <div class="card bg-light border-0 rounded shadow">
                        <div class="text-justify card-body">
                          <label>
                            {{ $t("pdpa-para1")}}
                          </label>
                        </div>
                        <div class="inner text-center">
                          <label>
                            <b>
                              <i class="mdi mdi-check"></i>&nbsp; {{ $t("understand-pdpa") }}
                            </b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 text-md-left">
                  <h5 class="m-0 title text-left">{{ $t("will-confirmation") }}</h5>
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <div class="card bg-light border-0 rounded shadow">
                        <div class="card-body">
                          <p>{{ $t("i") }}
                          <strong>{{
                            wasiat.user ? wasiat.user.name : "-"
                          }}</strong
                          >, {{ $t("mykad") }}
                          <strong>{{
                            wasiat.user ? wasiat.user.ic_number : "-"
                          }}</strong
                          >,</p>
                          <p>
                            <i class="mdi mdi-check"></i>
                            <strong>{{ $t("agree-and-understand") }}:</strong>
                          </p>
                          <p>
                            <ol>
                              <li v-if="wasiat.price == 1200">
                                {{ $t("appoint-awaris-executor-trustee") }}:
                              </li>
                              <ul v-if="wasiat.price == 1200">
                                <li>{{ $t("1m-below") }}</li>
                                <li>{{ $t("1m-to-2m") }}</li>
                                <li>{{ $t("above-2m") }}</li>
                              </ul>
                              <li v-if="wasiat.price != 1200"> 
                                {{ $t("appoint-executor-trustee") }}
                              </li>
                              <li>
                                {{ $t("revoke-will") }}
                              </li>
                            </ol>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12 text-md-left">
            <div class="row">
              <div class="col-md-12">
                <div class="row align-items-end">
                  <div class="col-md-12 text-center mt-4 mt-sm-0">
                    <div class="mt-md-4 mt-3 mt-sm-0">
                      <button
                      @click="back"
                      class="btn btn-outline-primary mr-2"
                      type="button"
                    ><i class="mdi mdi-arrow-left"></i>
                      {{ $t("back") }}
                    </button>
                      <button
                        @click="wasiatCertificate"
                        class="btn btn-primary mr-2"
                        v-if="this.wasiat.status == 2 && this.userRole =='Staff'"
                      >
                        <i class="mdi mdi-file-document"></i>{{ $t("will-cert") }}
                      </button>
                      <button
                        @click="wasiatCertificate"
                        class="btn btn-primary mr-2"
                        v-if="this.wasiat.status == 2 && this.userRole =='Admin' && this.userAccess.organization.code == 'TN'"
                      >
                        <i class="mdi mdi-file-document"></i>{{ $t("will-cert") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
