var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("basic-will-info")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "mb-4 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiat-tracking1")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "step "
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 0 ? 'circle-active' : 'circle'
  }, [_vm._v("1")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("basic-will-application")) + " -" + _vm._s(_vm.$t("date")) + " " + _vm._s(_vm._f("getConvertDate")(_vm.wasiat ? _vm.wasiat.recorded_wasiat_date : "")) + " ")]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "step"
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 1 ? 'circle-active' : 'circle'
  }, [_vm._v("2")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("awaiting-awaris-confirmation")))]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "step"
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 2 && _vm.amendment_status == null ? 'circle-active' : 'circle'
  }, [_vm._v("3")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("basic-will-confirmed")) + " -" + _vm._s(_vm.$t("date")) + " " + _vm._s(_vm._f("getConvertDate")(_vm.wasiat ? _vm.wasiat.approved_at : "")) + " ")]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "step"
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 2 && _vm.amendment_status == 0 ? 'circle-active' : 'circle'
  }, [_vm._v("4")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("comp-will-application")))]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "step"
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 2 && _vm.amendment_status == 1 ? 'circle-active' : 'circle'
  }, [_vm._v("5")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("amendment.confirmation")) + " -" + _vm._s(_vm.$t("date")) + " " + _vm._s(_vm._f("getConvertDate")(this.recorded_amendment_date ? this.recorded_amendment_date : "")) + " ")]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "step"
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 2 && _vm.amendment_status == 2 ? 'circle-active' : 'circle'
  }, [_vm._v("6")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("ver-comp-will-draft")))]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "step"
  }, [_c('div', [_c('div', {
    class: _vm.wasiat.status == 2 && _vm.amendment_status == 4 ? 'circle-active' : 'circle'
  }, [_vm._v("7")]), _c('div', {
    staticClass: "line"
  })]), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("comp-will-verification")))]), _c('br'), _c('br')])])])])])])])])]), _c('div', {
    staticClass: "row mt-5"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.will-desc1")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "table-responsive bg-light shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.no-wasiat")) + " ")]), _c('td', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat ? _vm.wasiat.wasiat_no : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.type")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.wasiat_type == 1 ? "Wasiat Islam" : "Wasiat Konvensional") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("date-of-will")))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("getConvertDate")(_vm.wasiat.created_at)) + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("date-will-accepted")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat ? _vm.wasiat.wasiat_received_date : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.price")))]), _c('td', [_vm._v("RM" + _vm._s(_vm.wasiat.price))]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v("Status")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.status == 0 ? "Sedang Membuat Wasiat" : _vm.wasiat.status == 1 ? "Tunggu Pengesahan" : _vm.wasiat.status == 2 ? "Wasiat Lulus" : "Wasiat Batal") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("organization")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.organization ? _vm.wasiat.organization.name : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.branch ? _vm.wasiat.branch.branch_name : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("agent-name")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.agent ? _vm.wasiat.agent.name : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("agent-phone-no")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.agent ? _vm.wasiat.agent.mobile_number : "-") + " ")])])])])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.testator-desc")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "table-responsive bg-light shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("designation")) + " ")]), _c('td', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.designation : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.name : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("new-ic")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.ic_number : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.other_ic_number : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.gender : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("citizen")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.nationality : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.mobile_number : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.home_phone_number : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.email : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("mailing-address")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_address : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_postcode : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_city : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_state : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("permanent-address")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_address : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_postcode : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_city : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_state : "-") + " ")])])])])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("necessary-info-to-contact")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "table-responsive bg-light shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " ")]), _c('td', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.name : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("relationship-with-heirs")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.relationship : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.mobile_number : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.home_phone_number : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("mailing-address")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.address : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.postcode : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.city : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.state : "-") + " ")])])])])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('h5', {
    staticClass: "title text-left"
  }, [_vm._v(_vm._s(_vm.$t("list-of-witnesses1")))]), _vm._l(_vm.witness, function (witness, index) {
    return _c('div', {
      key: index,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-12"
    }, [_c('h6', {
      staticClass: "title text-left mt-4"
    }, [_vm._v(" " + _vm._s(_vm.$t("witness1")) + " " + _vm._s(index + 1) + " ")]), _c('div', {
      staticClass: "table-responsive bg-light shadow rounded"
    }, [_c('table', {
      staticClass: "table mb-0 table-center"
    }, [_c('tbody', [_c('tr', [_c('th', {
      staticClass: "bg-secondary",
      staticStyle: {
        "width": "20%"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " ")]), _c('td', {
      staticStyle: {
        "width": "30%"
      }
    }, [_vm._v(" " + _vm._s(witness ? witness.name : "-") + " ")]), _c('th', {
      staticClass: "bg-secondary",
      staticStyle: {
        "width": "20%"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("new-ic")) + " ")]), _c('td', [_vm._v(" " + _vm._s(witness ? witness.ic_number : "-") + " ")])]), _c('tr', [_c('th', {
      staticClass: "bg-secondary"
    }, [_vm._v(" " + _vm._s(_vm.$t("mailing-address")) + " ")]), _c('td', [_vm._v(_vm._s(witness ? witness.address : "-"))]), _c('th', {
      staticClass: "bg-secondary"
    }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(_vm._s(witness ? witness.postcode : "-"))])]), _c('tr', [_c('th', {
      staticClass: "bg-secondary"
    }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(_vm._s(witness ? witness.city : "-"))]), _c('th', {
      staticClass: "bg-secondary"
    }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(_vm._s(witness ? witness.state : "-"))])])])])])])]);
  })], 2)])])])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('h5', {
    staticClass: "title text-left"
  }, [_vm._v(_vm._s(_vm.$t("info-about-amendments")))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "pindaan"
  }, [_vm.wasiat.status == 2 ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-end m-3"
  }, [[null, 5].includes(_vm.amendment_index_status) && _vm.userRole == 'Staff' ? _c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": _vm.applyAmendment
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.apply-new")))]) : _vm._e()], 1)]) : _vm._e()]), _c('div', {
    staticClass: "table-responsive bg-light shadow rounded"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("index")) + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.officer")) + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("agent-phone-no")) + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.date-of-amendment")) + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.of")) + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" Status ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")])])]), _vm.amendment_array == null ? _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("no-amendments")))])])])]) : _vm._e(), _vm.amendment_array ? _c('tbody', _vm._l(_vm.amendment_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(item ? item.incharged_by_id : "-") + " ")]), _c('td', [_vm._v(" 999 ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("getConvertDate")(item ? item.created_at : '-')) + " ")]), _c('td', [_vm._v(" " + _vm._s(item ? item.count : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(item.status ? item.status == 0 ? this.$t("in-process") : item.status == 1 ? this.$t("application-sent") : item.status == 2 ? "Terima Draf Pindaan" : item.status == 4 ? "Pengesahan Saksi" : item.status == 5 ? "Pindaan Berjaya" : "-" : "-") + " ")]), item.status == 0 ? _c('td', {
      staticClass: "text-left"
    }, [_c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'pindaan.part_a',
          params: {
            wasiat_id: item.amendmentable_id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")])], 1) : _vm._e(), item.status == 1 ? _c('td', {
      staticClass: "text-left"
    }, [_c('b-button', {
      staticClass: "btn-sm btn-secondary",
      on: {
        "click": function ($event) {
          return _vm.VerifiedWasiatStatus1(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("verification")) + " ")])], 1) : _vm._e(), item.status == 2 ? _c('td', {
      staticClass: "text-left"
    }, [_c('b-button', {
      staticClass: "btn-sm btn-secondary",
      on: {
        "click": function ($event) {
          return _vm.VerifiedWasiat(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("verification-draft-will")) + " ")])], 1) : _vm._e(), item.status == 4 ? _c('td', {
      staticClass: "text-left"
    }, [_vm._v(" " + _vm._s(_vm.$t("draft-received")) + " ")]) : _vm._e(), item.status == 5 ? _c('td', {
      staticClass: "text-left"
    }, [_vm._v(" " + _vm._s(_vm.$t("print-cert")) + " ")]) : _vm._e()]);
  }), 0) : _vm._e()])])])])])])])])]) : _vm._e(), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa")) + " ")]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow"
  }, [_c('div', {
    staticClass: "text-justify card-body"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("pdpa-para1")) + " ")])]), _c('div', {
    staticClass: "inner text-center"
  }, [_c('label', [_c('b', [_c('i', {
    staticClass: "mdi mdi-check"
  }), _vm._v(" " + _vm._s(_vm.$t("understand-pdpa")) + " ")])])])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(_vm._s(_vm.$t("will-confirmation")))]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("i")) + " "), _c('strong', [_vm._v(_vm._s(_vm.wasiat.user ? _vm.wasiat.user.name : "-"))]), _vm._v(", " + _vm._s(_vm.$t("mykad")) + " "), _c('strong', [_vm._v(_vm._s(_vm.wasiat.user ? _vm.wasiat.user.ic_number : "-"))]), _vm._v(",")]), _c('p', [_c('i', {
    staticClass: "mdi mdi-check"
  }), _c('strong', [_vm._v(_vm._s(_vm.$t("agree-and-understand")) + ":")])]), _c('p', [_c('ol', [_vm.wasiat.price == 1200 ? _c('li', [_vm._v(" " + _vm._s(_vm.$t("appoint-awaris-executor-trustee")) + ": ")]) : _vm._e(), _vm.wasiat.price == 1200 ? _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$t("1m-below")))]), _c('li', [_vm._v(_vm._s(_vm.$t("1m-to-2m")))]), _c('li', [_vm._v(_vm._s(_vm.$t("above-2m")))])]) : _vm._e(), _vm.wasiat.price != 1200 ? _c('li', [_vm._v(" " + _vm._s(_vm.$t("appoint-executor-trustee")) + " ")]) : _vm._e(), _c('li', [_vm._v(" " + _vm._s(_vm.$t("revoke-will")) + " ")])])])])])])])])])])])]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 text-md-left"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('div', {
    staticClass: "mt-md-4 mt-3 mt-sm-0"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mr-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_c('i', {
    staticClass: "mdi mdi-arrow-left"
  }), _vm._v(" " + _vm._s(_vm.$t("back")) + " ")]), this.wasiat.status == 2 && this.userRole == 'Staff' ? _c('button', {
    staticClass: "btn btn-primary mr-2",
    on: {
      "click": _vm.wasiatCertificate
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(_vm._s(_vm.$t("will-cert")) + " ")]) : _vm._e(), this.wasiat.status == 2 && this.userRole == 'Admin' && this.userAccess.organization.code == 'TN' ? _c('button', {
    staticClass: "btn btn-primary mr-2",
    on: {
      "click": _vm.wasiatCertificate
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(_vm._s(_vm.$t("will-cert")) + " ")]) : _vm._e()])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }